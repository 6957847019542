import axios from 'axios'
import Cookies from 'js-cookies';

import { DASHBOARD_API_BASE_URL } from '../index';

const login = async (payload) =>{
    try{
        const response = await axios.post(`${DASHBOARD_API_BASE_URL}/login`, payload);

        const {token, idCompany, idUser, accessLevel, colorTheme} = response.data; 
        console.log(colorTheme)
        Cookies.setItem('token', token);
        Cookies.setItem('companyId', idCompany);
        Cookies.setItem('userId', idUser);
        Cookies.setItem('accessLevel', accessLevel);
        Cookies.setItem('colorTheme', colorTheme);
        
        return {
            status: true
        }
    }catch(err){
        return {
            status: false, 
        }
    }
}


const checkLogin = async (token) =>{
    try{
        const response = await axios.get(`${DASHBOARD_API_BASE_URL}/login/check`, {
            headers: {
                Authorization: token,
            }
        });

        const { idCompany, idUser, accessLevel, colorTheme} = response.data; 

        console.log('idCompany', idCompany)

        Cookies.setItem('token', token);
        Cookies.setItem('companyId', idCompany);
        Cookies.setItem('userId', idUser);
        Cookies.setItem('accessLevel', accessLevel);
        
        if(String(Cookies.getItem('colorTheme')) === 'undefined' || !Cookies.getItem('colorTheme')){
            Cookies.setItem('colorTheme', colorTheme)
        }

        return {
            status: true
        }

    }catch(err){
        return {
            status: false, 
        }
    }
}


export { login, checkLogin };