import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Login from '../pages/login';
import { Dashboard } from '../pages/dashboard';

const AppRoutes = () =>{
    return(
        <Router>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path='/:token?/:colorTheme?' element={<Dashboard/>}/>
                <Route path="*" element={<Navigate to ="/:token?/:colorTheme?" />}/>
            </Routes>
        </Router>
    )
}

export default AppRoutes;