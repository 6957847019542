import Cookies from 'js-cookies';
 
const DASHBOARD_API_BASE_URL = process.env.REACT_APP_DASHBOARD_API_BASE_URL || 'http://localhost:3003';

const getHeaders = () =>{

   const token = Cookies.getItem('token');
   const companyId = Cookies.getItem('companyId');
   const userId = Cookies.getItem('userId');

   return {
       'Access-Control-Allow-Origin': '*',
       'Content-Type': 'application/json; charset=utf-8',
       Authorization: token,
       companyId,
       userId,
   }
}

export { DASHBOARD_API_BASE_URL, getHeaders } 