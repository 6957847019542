import React, { useEffect, useState, useContext, useCallback } from 'react';
import _ from 'lodash';
import { useParams, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookies';
import { DatePicker, Button, Select, Spin } from 'antd';
import { ResponsiveBar } from '@nivo/bar'
import { ResponsivePie } from '@nivo/pie'
import { LoadingOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import * as transactionsRoutes from '../../providers/api/transactions/transactions';
import { checkLogin } from '../../providers/api/auth/auth';
import { WindowDimensionsContext } from '../../hooks/windowDimensionsProvider'
import {
    Chart,
    ChartTitle,
    ChartsContainer,
    FilterContainer,
    PageContainer,
    TotalItem,
    TotalsContainer,
} from './styles';
import { getCompaniesService } from '../../providers/api/companies';

export const Dashboard = () => {
    const routerParams = useParams();
    const navigate = useNavigate();
    const windowDimensions = useContext(WindowDimensionsContext);
    const lastDaysCurrent = 30;
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000;
    const initialDateValue = new Date(new Date().getTime() - lastDaysCurrent * oneDay);

    const [companies, setCompanies] = useState([]);
    const [companySelected, setCompanySelected] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialDate, setInitialDate] = useState(dayjs(initialDateValue));
    const [finalDate, setFinalDate] = useState(dayjs(now));
    const patternColorsThemes = {
        indigo: [
            '#484c7f',
            '#a0c1b8',
            '#7394b9',
            '#79b6bd',
            '#d8d419'
        ],
        blue: [
            '#ED5782',
            '#e5df88',
            '#7258db',
            '#a6a6a4',
            '#c9b8b8'
        ],
        orange: [
            '#ef7e56',
            '#44558f',
            '#ded5c4',
            '#f59292',
            '#90a4ae'
        ],
        tradewind: [
            '#6aab9c',
            '#f7b36b',
            '#97B0AA',
            '#4D6E94',
            '#81A1CA',
            '#ED5782'
        ],
        cyan: [
            '#00bdaa',
            '#c1c0b9',
            '#537791',
            '#9cbf3b',
            '#cdb60c'
        ],
        blush: [
            '#ED5782',
            '#e5df88',
            '#726a95',
            '#a6a6a4',
            '#c9b8b8'
        ],
        monalisa: [
            '#ED5782',
            '#e5df88',
            '#726a95',
            '#a6a6a4',
            '#c9b8b8'
        ],
        green: [
            '#7EB93F',
            '#b9bc6d',
            '#427a5b',
            '#4e7376',
            '#a6a6a4'
        ],
        red: [
            '#ff5959',
            '#ffad5a',
            '#4f9da6',
            '#c06c84',
            '#f67280'
        ]
    }

    const [currentsColorTheme, setCurrentColorsTheme] = useState([]);
    const [totalTransactions, setTotalTransactions] = useState(0);
    const [transactionsAmount, setTransactionsAmount] = useState(0);
    // const [transactionsAmountOverprice, setTransactionsAmountOverprice] = useState(0);
    const [avarageTicket, setAvarageTicket] = useState(0);
    const [transactionsByDate, setTransactionByDate] = useState([]);
    const [transactionsByBrand, setTransactionByBrand] = useState([]);
    const [transactionType, setTransactionType] = useState({});
    const [transactionTypeData, setTransactionTypeData] = useState([]);
    const [cardTransactionStatus, setCardTransactionStatus] = useState([]);

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    const handleCheckAuth = useCallback(async () => {
        const token = routerParams.token || Cookies.getItem('token');

        if (token) {
            if (!Cookies.getItem('colorTheme')) {
                Cookies.setItem('colorTheme', routerParams.colorTheme)
            }

            const response = await checkLogin(token);

            if (!response.status) {
                Cookies.removeItem('token');
                return navigate("/login");
            }

            // if(routerParams.token){ 
            //    var url = window.location.toString();
            //    return window.location = url.replace(`${routerParams.token}/${routerParams.colorTheme}`, '');
            // }

            // if(Cookies.getItem('token')){
            //     return;
            // }


        } else {
            navigate("/login");
        }

    }, [navigate, routerParams]);

    const getDateFormat = useCallback((date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${date.getFullYear()}-${month}-${day}`
    }, [])

    const handleTransactions = useCallback(async () => {
        setLoading(true)

        await handleCheckAuth();

        let transactionsCount = 0;
        let transactionsAmount = 0;
        // let transactionsAmountOverprice = 0;
        let transactionsByDate = [];
        let transactionsByBrand = [];
        let transactionType = {};
        let transactionTypeData = [];
        let cardByTransactionStatus = [];

        if (!initialDate || !finalDate) {
            setLoading(false)
            alert('Informe a data de início e fim para buscar as informações.');
            return;
        }

        let filter = {
            initialDate: getDateFormat(initialDate.toDate()),
            finalDate: getDateFormat(finalDate.toDate()),
            idCompany: companySelected?.value || Cookies.getItem('companyId'),
        }

        const [cards, pix] = await Promise.all([
            transactionsRoutes.getCardsTransactions(filter),
            transactionsRoutes.getPixTransactions(filter),
            // transactionsRoutes.getCashTransactions(filter);
        ]);

        setLoading(false);

        if (cards.status) {
            transactionsCount += cards.transactionData.count;
            transactionsAmount += cards.transactionData.amount;
            transactionsByDate = transactionsByDate.concat(cards.transactionData.transactionsDate);
            transactionsByBrand = transactionsByBrand.concat(cards.transactionData.transactionsBrand);
            transactionType = _.merge(transactionType, cards.transactionData.transactionsType);
            transactionTypeData = transactionTypeData.concat(cards.transactionData.transactionTypeData);
            cardByTransactionStatus = cardByTransactionStatus.concat(cards.transactionData.cardByTransactionStatus);
            // transactionsAmountOverprice += cards.transactionData.overpriceTransactions;
        }

        // if(cash.status){
        // transactionsCount += cash.transactionData.length;
        // }

        if (pix.status) {
            transactionsCount += pix.transactionData.count;
            transactionsAmount += pix.transactionData.amount;
            transactionsByDate = transactionsByDate.concat(pix.transactionData.transactionsDate);
            transactionType = _.merge(transactionType, pix.transactionData.transactionsType);
            transactionTypeData = transactionTypeData.concat(pix.transactionData.transactionTypeData);
            // transactionsAmountOverprice += pix.transactionData.overpriceTransactions;
        }

        setTotalTransactions(transactionsCount);
        setTransactionsAmount(transactionsAmount);
        // setTransactionsAmountOverprice(transactionsAmountOverprice);
        setAvarageTicket(transactionsAmount / transactionsCount);
        setTransactionByDate(transactionsByDate);
        setTransactionByBrand(transactionsByBrand);
        setTransactionType(transactionType);
        setTransactionTypeData(transactionTypeData);
        setCardTransactionStatus(cardByTransactionStatus);
    }, [companySelected, initialDate, finalDate, getDateFormat, handleCheckAuth])

    const handleChartColors = () => {
        const pattern = Cookies.getItem('colorTheme');
        setCurrentColorsTheme(patternColorsThemes[pattern] ? patternColorsThemes[pattern] : { scheme: 'nivo' });
    }

    const disabledDate = useCallback((current) => {
        return current.toDate().getTime() > new Date().getTime();
    }, []);

    const handleCompany = useCallback((idCompany) => {
        const companySelect = companies.find(company => company.value === idCompany)
        if (companySelect) {
            setCompanySelected(companySelect)
        }
    }, [companies]);

    const handleCompanies = useCallback(async () => {
        const companyId = Cookies.getItem('companyId');
        const { companies } = await getCompaniesService(companyId)
        const mapperCompanies = companies.map(company => ({
            label: company.companyName,
            value: company.id,
        }))
        setCompanies(mapperCompanies);
        const companySelect = mapperCompanies.find(company => company.value === companyId);
        setCompanySelected(companySelect)
    }, []);

    useEffect(() => {
        async function getTransactions() {
            await handleTransactions();
            await handleCompanies();
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
        getTransactions()
    }, [])

    useEffect(function getChartColors() {
        handleChartColors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Spin
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '30vh' }}
                indicator={<LoadingOutlined style={{ fontSize: 60 }} spin />}
                spinning={loading}
            />
            {!loading && (
                <PageContainer windowDimensions={windowDimensions} >
                    <FilterContainer>
                        <Select
                            placeholder="Filtrar por empresa"
                            notFoundContent="Nenhuma empresa encontrada"
                            options={companies}
                            onChange={handleCompany}
                            value={companySelected}
                        />
                        <DatePicker
                            placeholder="Data inicial"
                            format={'DD/MM/YYYY'}
                            value={initialDate}
                            disabledDate={disabledDate}
                            onChange={(val) => setInitialDate(val)}
                        />
                        <DatePicker
                            placeholder="Data final"
                            format={'DD/MM/YYYY'}
                            value={finalDate}
                            disabledDate={disabledDate}
                            onChange={(val) => setFinalDate(val)}
                        />
                        <Button type="primary" onClick={handleTransactions}>Buscar</Button>
                    </FilterContainer>
                    <TotalsContainer windowDimensions={windowDimensions}>
                        <TotalItem windowDimensions={windowDimensions} title="Transações" value={totalTransactions} />
                        <TotalItem windowDimensions={windowDimensions} title="Ticket Médio" value={formatter.format(avarageTicket || 0)} />
                        <TotalItem windowDimensions={windowDimensions} title="Total transacionado" value={formatter.format(transactionsAmount)} />
                        {/* <TotalItem windowDimensions={windowDimensions} title="Rendimento" value={formatter.format(transactionsAmountOverprice)} />{*/}
                    </TotalsContainer>
                    <ChartsContainer windowDimensions={windowDimensions}>
                        <Chart windowDimensions={windowDimensions} style={{ height: 230 }}>
                            <ChartTitle>
                                Transações por data
                            </ChartTitle>
                            <ResponsiveBar
                                colors={currentsColorTheme}
                                // width={1000}
                                width={windowDimensions.width >= 1000 && windowDimensions.width <= 1400 ? 380 : windowDimensions.width <= 640 ? 220 : 580}
                                data={transactionsByDate}
                                keys={Object.keys(transactionType)}
                                motionConfig="molasses"
                                indexBy="date"
                                margin={{ top: 50, right: 0, bottom: 50, left: 0 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={windowDimensions.width <= 640 ? { tickRotation: 90 } : transactionsByDate.length > 5 ? { tickRotation: 30 } : {}}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}
                                legends={[
                                    {
                                        dataFrom: 'keys',
                                        anchor: 'bottom-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 120,
                                        translateY: 0,
                                        itemsSpacing: 2,
                                        itemWidth: 100,
                                        itemHeight: 20,
                                        itemDirection: 'left-to-right',
                                        itemOpacity: 0.85,
                                        symbolSize: 20,
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ]}
                                role="application"
                                ariaLabel="Nivo bar chart demo"
                                barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
                            >
                            </ResponsiveBar>
                        </Chart>

                        <Chart windowDimensions={windowDimensions} style={{ height: 260 }}>
                            <ChartTitle>
                                Ranking de bandeiras de cartão
                            </ChartTitle>
                            <ResponsivePie
                                width={windowDimensions.width >= 1000 && windowDimensions.width <= 1400 ? 380 : windowDimensions.width <= 640 ? 300 : 580}
                                // width={820}
                                colors={currentsColorTheme}
                                colorBy="index"
                                motionConfig="molasses"
                                data={transactionsByBrand}
                                margin={{ top: 40, right: 0, bottom: 80, left: 0 }}
                                innerRadius={0.5}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                borderWidth={1}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            0.2
                                        ]
                                    ]
                                }}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{ from: 'color' }}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            2
                                        ]
                                    ]
                                }}
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: 'rgba(255, 255, 255, 0.3)',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'Amex'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'c'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'go'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'python'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'scala'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'lisp'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'elixir'
                                        },
                                        id: 'lines'
                                    },
                                    {
                                        match: {
                                            id: 'javascript'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                legends={[
                                    {
                                        anchor: 'bottom',
                                        direction: 'row',
                                        justify: false,
                                        translateX: 0,
                                        translateY: 56,
                                        itemsSpacing: 0,
                                        itemWidth: 130,
                                        itemHeight: 18,
                                        itemTextColor: '#999',
                                        itemDirection: 'top-to-bottom',
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemTextColor: '#000'
                                                }
                                            }
                                        ]
                                    }
                                ]}
                            >
                            </ResponsivePie>
                        </Chart>
                    </ChartsContainer>

                    <ChartsContainer windowDimensions={windowDimensions}>
                        <Chart windowDimensions={windowDimensions} style={{ height: 200 }}>
                            <ChartTitle>
                                Transações por tipo
                            </ChartTitle>
                            <ResponsiveBar
                                colors={currentsColorTheme}
                                colorBy="index"
                                data={transactionTypeData}
                                width={windowDimensions.width >= 1000 && windowDimensions.width <= 1400 ? 380 : windowDimensions.width <= 640 ? 300 : 580}
                                keys={['total']}
                                indexBy="transactionType"
                                layout='horizontal'
                                groupMode='stacked'
                                margin={{ top: 50, bottom: 50, right: 50, left: 100 }}
                                axisBottom={windowDimensions.width <= 640 ? {
                                    tickRotation: 90
                                } : {}}
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                motionConfig="molasses"
                                defs={[
                                    {
                                        id: 'dots',
                                        type: 'patternDots',
                                        background: 'inherit',
                                        color: '#38bcb2',
                                        size: 4,
                                        padding: 1,
                                        stagger: true
                                    },
                                    {
                                        id: 'lines',
                                        type: 'patternLines',
                                        background: 'inherit',
                                        color: '#eed312',
                                        rotation: -45,
                                        lineWidth: 6,
                                        spacing: 10
                                    }
                                ]}
                                fill={[
                                    {
                                        match: {
                                            id: 'fries'
                                        },
                                        id: 'dots'
                                    },
                                    {
                                        match: {
                                            id: 'sandwich'
                                        },
                                        id: 'lines'
                                    }
                                ]}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}
                                axisTop={null}
                                axisRight={null}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}
                                // margin={{ left: 200 }}
                                // padding={{left: 200}}
                                // legends={null}
                                axisLeft={{
                                    // padding: {left: 200},
                                    tickSize: 0,
                                    // tickPadding: 2,
                                    // tickRotation: 68,
                                    // legend: 'food',
                                    // legendPosition: 'middle',
                                    // legendOffset: -60
                                }}
                                role="application"
                                ariaLabel="Nivo bar chart demo"
                                barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
                            >
                            </ResponsiveBar>
                        </Chart>

                        <Chart windowDimensions={windowDimensions} style={{ height: 200 }}>
                            <ChartTitle >
                                Transações por status
                            </ChartTitle>
                            <ResponsiveBar
                                width={windowDimensions.width >= 1000 && windowDimensions.width <= 1400 ? 380 : windowDimensions.width <= 640 ? 265 : 580}
                                colors={currentsColorTheme}
                                colorBy="index"
                                motionConfig="molasses"
                                data={cardTransactionStatus}
                                keys={['total']}
                                indexBy="status"
                                layout='horizontal'
                                groupMode='stacked'
                                margin={{ top: 50, bottom: 50, left: 85, right: 10 }}
                                padding={0.3}
                                valueScale={{ type: 'linear' }}
                                axisBottom={windowDimensions.width <= 640 ? {
                                    tickRotation: 90
                                } : {}}
                                indexScale={{ type: 'band', round: true }}

                                borderColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}
                                axisTop={null}
                                axisRight={null}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            1.6
                                        ]
                                    ]
                                }}

                                axisLeft={{
                                    tickSize: 0,
                                }}
                                role="application"
                                ariaLabel="Nivo bar chart demo"
                                barAriaLabel={function (e) { return e.id + ": " + e.formattedValue + " in country: " + e.indexValue }}
                            >
                            </ResponsiveBar>
                        </Chart>
                    </ChartsContainer>
                </PageContainer>
            )}
        </>
    )
}