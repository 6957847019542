import axios from 'axios';

import { DASHBOARD_API_BASE_URL, getHeaders } from '../index';

export const getCardsTransactions = async (params) =>{
    try{
        const response = await axios.post(`${DASHBOARD_API_BASE_URL}/transactions/cards`, params, {
            headers: getHeaders(),
        });
        return {
            transactionData: response.data,
            status: true
          }
    }catch(err){
      return {
        transactionData: [],
        status: false
      }
    }
   
}

export const getCashTransactions = async (params) =>{
    try{
        const response = await axios.post(`${DASHBOARD_API_BASE_URL}/transactions/cash`, params,{
            headers: getHeaders(),
        });
        return {
            transactionData: response.data,
            status: true
          }
    }catch(err){
      return {
        transactionData: [],
        status: false
      }
    }
   
}

export const getPixTransactions = async (params) =>{
    try{
       const response = await axios.post(`${DASHBOARD_API_BASE_URL}/transactions/pix`, params, {
            headers: getHeaders(),
        });
      
        return {
            transactionData: response.data,
            status: true
          }
    }catch(err){
      return {
        transactionData: [],
        status: false
      }
    }
   
}

